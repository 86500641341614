<template>
  <div id="merchant-page">
    <div class="vx-row">
      <div class="vx-col">
        <div class="page-title p-2 mb-6">
          <div class="vx-col w-full flex items-center data-list-btn-container">
            <div :style="$route.query.tab === tab ? 'background: #1C5BFE; color: white' : ''" class="relative vx-row m-2 py-2 pl-4 pr-5 cursor-pointer d-theme-dark-bg rounded-md hover:shadow-drop"
              v-for="tab in tabs" :key="tab.index" @click="changeTabFilter(tab)">
              <div class="text-sm">{{$t(tab)}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="xl:float-left clearfix xl:w-1/2 mt-4 w-full">
      <export-supplies class="mr-4 float-left whitespace-nowrap"></export-supplies>
    </div>
    <shipblu-table
      :sst="true"
      pagination
      orders
      @search="handleSearch"
      @keyup.enter.native="handleSearch"
      search
      :max-items="maximumItems"
      :data="supplies"
      :tableLoader="tableLoader"
    >
      <div
        slot="header"
        class="flex flex-wrap-reverse items-center flex-grow justify-between"
      >
      </div>
      <template slot="thead">
        <shipblu-th>{{$t('ID')}}</shipblu-th>
        <shipblu-th>{{$t('Request Date')}}</shipblu-th>
        <shipblu-th>{{$t('Supplies')}}: {{$t('Quantity')}}</shipblu-th>
        <shipblu-th>{{$t('Merchant')}}</shipblu-th>
        <shipblu-th>{{$t('Phone Number')}}</shipblu-th>
        <shipblu-th>{{$t('Zone')}}</shipblu-th>
        <shipblu-th>{{$t('Address')}}</shipblu-th>
        <shipblu-th>{{$t('Status')}}</shipblu-th>
        <shipblu-th>{{$t('Actions')}}</shipblu-th>
      </template>

      <template slot-scope="{ data }">
        <shipblu-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" :color="`${getStyle(data[indextr])}`">
          <shipblu-td :data="data[indextr].id" :style="`color: ${getStyle(data[indextr])}`">
            <p class="text-darkblue py-2 px-3">{{ data[indextr].id }}</p>
          </shipblu-td>

          <shipblu-td :data="data[indextr].created">
            <p class="text-darkblue mb-1"> {{ new Date(data[indextr].created).toLocaleDateString('fr-CA') }}</p>
            <p class="text-darkblue"> {{ new Date(data[indextr].created).toLocaleTimeString('en-GB') }}</p>
          </shipblu-td>

          <shipblu-td :data="data[indextr].created">
            <div v-for="item in tr.supply_request_items" :key="item.index">
              <span v-if="item.count > 0 && item.package_size">{{ item.package_size.name.split('(')[0] }}: {{ item.count }} </span>
            </div>
          </shipblu-td>

          <shipblu-td :data="data[indextr].merchant">
            {{ data[indextr].merchant.name }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].merchant">
            {{ data[indextr].merchant.store_phone }}
          </shipblu-td>

          <template v-if="data[indextr].pickup_point">
            <shipblu-td :data="data[indextr].zone">
              {{ $t(data[indextr].pickup_point.address.zone.name) }}
            </shipblu-td>

            <shipblu-td :data="data[indextr].address">
              {{ data[indextr].pickup_point.address.line_1 }}, {{ data[indextr].pickup_point.address.line_2 }}
            </shipblu-td>
          </template>
          <template v-else>
            <shipblu-td :data="data[indextr].zone">
              {{ $t(data[indextr].merchant.address.zone.name) }}
            </shipblu-td>

            <shipblu-td :data="data[indextr].address">
              {{ data[indextr].merchant.address.line_1 }}, {{ data[indextr].merchant.address.line_2 }}
            </shipblu-td>
          </template>

          <shipblu-td :data="data[indextr].status" :style="`color: ${getStyle(data[indextr])}`">
            <template>
              <vx-tooltip position="left" :text="data[indextr].supply_request_items.map(item => item.package_size && item.package_size.short_code != 'OSZ' ? `${item.package_size.short_code}: ${item.count_delivered}` : '').filter(element => element !== '').join('\n')">
                <div class="m-auto flex items-center justify-center font-semibold">
                  <div><span class="status-icon mr-1"></span></div>
                  <span class="capitalize">{{ $t(getStatusLabel(data[indextr])) }}</span>
                </div>
              </vx-tooltip>
            </template>
          </shipblu-td>

          <shipblu-td class="active-link">
            <vs-dropdown
              vs-trigger-click
              class="dd-actions cursor-pointer"
            >
              <vs-button
                type="border"
                size="small"
                icon-pack="feather"
                icon="icon-more-horizontal"
                class="mr-2"
              >
              </vs-button>
              <vs-dropdown-menu style="width: 190px">
                <vs-dropdown-item
                v-if="$store.state.AppActiveUser.userRole !== 'admin'"
                  :disabled="data[indextr].status === 'delivered' || (data[indextr].status === 'requested' && data[indextr].supplies_price.total > 0)"
                  @click="editQuantity(data[indextr])"
                >
                  <span class="flex items-center">
                    <feather-icon
                      icon="CheckCircleIcon"
                      svgClasses="h-4 w-4"
                      class="mr-2"
                    />
                    <span>{{$t('Actual Quantity')}}</span>
                  </span>
                </vs-dropdown-item>
                <vs-dropdown-item
                  :disabled="data[indextr].status === 'delivered'"
                  @click="updateSupply(data[indextr])"
                >
                  <span class="flex items-center">
                    <feather-icon
                      icon="CheckCircleIcon"
                      svgClasses="h-4 w-4"
                      class="mr-2"
                    />
                    <span>{{$t('Delivered')}}</span>
                  </span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </shipblu-td>
        </shipblu-tr>
      </template>
    </shipblu-table>
    <shipblu-pagination :maximumItems="maximumItems" :totalRows="totalRows" :currentPage="currentPage" @currentPage="currentPage = $event" @changePageSize="changePageSize" />
    <edit-supplies-count :supplyModal="supplyModal" :data="supply" @supplyModal="supplyModal = $event" @loadSupplies="loadSupplies"></edit-supplies-count>
  </div>
</template>

<script>
import {sendRequest} from '../../http/axios/requestHelper.js'
import common  from '@/assets/utils/common'
import EditSuppliesCount from '../headOfFleet/components/EditSuppliesCount.vue'
import ShipbluTable from '../../layouts/components/ShipBluTable.vue'
import ShipbluTr from '../../layouts/components/ShipBluTr.vue'
import ShipbluTh from '../../layouts/components/ShipBluTh.vue'
import ShipbluTd from '../../layouts/components/ShipBluTd.vue'
import ExportSupplies from '../admin/components/ExportSupplies.vue'
import ShipbluPagination from '../../layouts/components/ShipbluPagination.vue'

export default {
  data () {
    return {
      maximumItems: localStorage.getItem('maxItems') ? Number(localStorage.getItem('maxItems')) : process.env.VUE_APP_MAXIMUM_ITEMS_TABLE,
      tabs: ['Requests', 'All'],
      tabsDic: {'0': 'Requests', '1': 'All'},
      tabsNameDic: {'Requests': 0, 'All': 1},
      activeTab: 0,
      supplies: [],
      supplyModal: false,
      type: '',
      supply: {},
      offset: 0,
      totalRows: 0,
      currentPage: (this.$route.query.page &&  Number(this.$route.query.page) > 0) ? Number(this.$route.query.page) : 1,
      packagesSize: [],
      packagesDic: {},
      deliveredSupply: {},
      searchVal: '',
      searchInProgress: false,
      searchedValue: ' ',
      tableLoader: false
    }
  },
  watch: {
    '$route.query.tab' () {
      this.activeTab = this.tabsNameDic[this.$route.query.tab]
      this.currentPage = 1
      this.loadSupplies()
    },
    activeTab () {
      this.$router.push({
        name: `${this.$store.state.AppActiveUser.userRole}-supplies`,
        query: {
          tab: this.tabsDic[this.activeTab],
          page: this.currentPage
        }
      }).catch(() => {})
    },
    currentPage () {
      this.offset = (this.currentPage - 1) * this.maximumItems
      this.$router.push({
        name: `${this.$store.state.AppActiveUser.userRole}-supplies`,
        query: {
          tab: this.tabsDic[this.activeTab],
          page: this.currentPage
        }
      }).catch(() => {})
      this.loadSupplies()
    },
    '$route.params.warehouseID' () {
      this.searchVal = ''
      this.maximumItems = process.env.VUE_APP_MAXIMUM_ITEMS_TABLE
      this.currentPage = 1
      this.loadPackagesSize()
      this.loadSupplies()
    }
  },
  methods: {
    changePageSize (val) {
      localStorage.setItem('maxItems', val)
      this.maximumItems = val
      this.currentPage = 1
      this.loadSupplies()
    },
    getStyle (data) {
      return common.getOrderStatusColor(data.status)
    },
    editQuantity (supply) {
      this.supplyModal = true
      this.supply = supply
    },
    changeTabFilter (tab) {
      this.activeTab = this.tabsNameDic[tab]
      this.$router.push({
        query: {
          tab: this.tabsDic[this.activeTab],
          page: this.activeTab,
          filter: this.filters,
          type: this.typeFilter
        }
      }).catch(() => {})
    },
    loadPackagesSize () {
      sendRequest(false, false, this, 'api/v1/package-sizes/', 'get', null, true,
        (response) => {
          const data = response.data
          const time = new Date()
          const PackageSizesDataTime = {data, time}
          localStorage.setItem('packages', JSON.stringify(PackageSizesDataTime))
          this.managePackageSize(response.data)
        }
      )
    },
    managePackageSize (packages) {
      this.packagesSize = packages
      this.packagesSize.forEach(packageSize => {
        this.packagesDic[packageSize.id] = packageSize.short_code
      })
    },
    getOrderStatusColor (order) {
      return common.getOrderStatusColor(this.getStatusLabel(order))
    },
    getStatusLabel (order) {
      return common.getStatusLabel(order, this.$store.state.AppActiveUser.userRole)
    },
    handleSearch (search) {
      this.offset = 0
      this.currentPage = 1
      this.searchVal = search.target && search.target !== null ? search.target.value : search
      return common.manageSearch(search, this.loadSuppliesSearch)
    },
    loadSuppliesSearch () {
      if (this.searchInProgress || this.searchedValue === this.searchVal) {
        return
      }
      this.loadSupplies()
    },
    loadSupplies () {
      this.searchInProgress = true
      this.tableLoader = true
      const query = `offset=${this.offset}&search=${this.searchVal}&limit=${this.maximumItems}&warehouse=${this.$route.params.warehouseID ? this.$route.params.warehouseID : ''}${(this.activeTab === 0 || this.activeTab === '0') ? '&status=created,requested,out_for_delivery,delivery_attempted' : '&status=created,requested,out_for_delivery,delivery_attempted,delivered'}`
      sendRequest(true, false, this, `api/v1/supplies/?${query}`, 'get', null, true,
        (response) => {
          this.supplies = response.data.results
          this.tableLoader = false
          this.totalRows = response.data.count
          this.searchedValue = this.searchVal
        }
      )
      this.searchInProgress = false
    },
    updateSupply (data) {
      this.deliveredSupply = data
      this.$vs.dialog({
        color: 'success',
        title: 'Confirm',
        text: 'Are you sure to delivered supply?',
        accept: this.confirmDeliveredSupply
      })
    },
    confirmDeliveredSupply () {
      sendRequest(false, false, this, `api/v1/supplies/${this.deliveredSupply.id}/`, 'patch', {status: 'delivered'}, true,
        () => {
          this.loadSupplies()
        }
      )
    }
  },
  components: {
    EditSuppliesCount,
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd,
    ExportSupplies,
    ShipbluPagination
  },
  created () {
    this.activeTab = this.$route.query.tab ? this.tabsNameDic[this.$route.query.tab] : '0'
    this.offset = (this.currentPage - 1) * this.maximumItems
    this.currentPage = this.$route.query.page ? Number(this.$route.query.page) : 1
    if (localStorage.getItem('packages') && (new Date() - new Date(JSON.parse(localStorage.getItem('packages')).time)) / (60 * 60 * 1000) <= 1) {
      this.managePackageSize(JSON.parse(localStorage.getItem('packages')).data)
    } else {
      this.loadPackagesSize()
    }
    if (this.$route.query.tab) this.loadSupplies()
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/vuexy/apps/email.scss";
.con-pagination-table{
  display: none;
}

</style>
